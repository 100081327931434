import { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';

import {
    ManagedConfigs,
    setDeviceDetails,
    setIsReactNative,
    setNotificationToken,
} from 'src/store/reactNativeSlice';

type ReactNativeInjectedObject = {
    deviceConfigs?: {
        batteryLevel?: number;
        deviceUniqueId?: string;
        managedConfigs: ManagedConfigs;
    };
    expoPushToken?: string;
};

// Extend the Window interface to include the ReactNativeWebView object
declare global {
    interface Window {
        ReactNativeWebView?: {
            postMessage: (message: string) => void;
            injectedObjectJson?: () => string;
        };
    }
}

/**
 * This is a custom protocol that is used to communicate between the webview and the native app.
 * The web app (this repo) will send messages using values defined in WebAction, and the native app
 * will respond with values defined in NativeAction.
 */
export const WebAction: { [key: string]: string } = {
    ConnectionStart: 'connection:start',
    ConnectionStop: 'connection:stop',
    NotificationSend: 'notification:send',
};

export const NativeAction: { [key: string]: string } = {
    ConnectionStatus: 'connection:status',
    DeviceDetails: 'device:details',
    NotificationToken: 'notification:token',
};

// Generate union type based on the keys of the Action objects
type WebActionType = (typeof WebAction)[keyof typeof WebAction];
type NativeActionType = (typeof NativeAction)[keyof typeof NativeAction];

export const sendMessageToNative = (action: WebActionType, payload?: any) => {
    try {
        window.ReactNativeWebView?.postMessage(JSON.stringify({ action, payload }));
    } catch (error) {
        console.warn('Error sending message to native app', error);
    }
};

export default function ReactNativeContainer({ children }: PropsWithChildren) {
    const dispatch = useDispatch();

    useEffect(() => {
        window.onload = () => {
            // Check if the ReactNativeWebView object's injectedObjectJson function is available
            if (window.ReactNativeWebView?.injectedObjectJson) {
                // If it is, we are in a React Native environment
                dispatch(setIsReactNative(true));

                try {
                    const nativeObject = JSON.parse(
                        window.ReactNativeWebView.injectedObjectJson()
                    ) as ReactNativeInjectedObject;

                    if (nativeObject) {
                        console.info('Native object:', nativeObject);

                        const { expoPushToken, deviceConfigs } = nativeObject;
                        if (deviceConfigs) {
                            console.info('Setting device details:', deviceConfigs);
                            dispatch(setDeviceDetails(deviceConfigs));
                        }

                        if (expoPushToken) {
                            console.info('Setting expo token:', expoPushToken);
                            dispatch(setNotificationToken(expoPushToken));
                        }
                    }
                } catch (error) {
                    console.error('Error getting native injected object', error);
                }
            }
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        window.addEventListener('message', (event: MessageEvent) => {
            if (!event || !event.data || !event.data.action) {
                return;
            }

            const { action, payload } = event.data as { action: NativeActionType; payload: any };

            try {
                switch (action) {
                    case NativeAction.DeviceDetails:
                        console.info('Device details:', payload);
                        dispatch(setDeviceDetails(payload));
                        break;
                    case NativeAction.NotificationToken:
                        console.info('Notification token:', payload);
                        dispatch(setNotificationToken(payload));
                        break;
                    default:
                        console.warn('Unhandled action:', { event });
                        break;
                }
            } catch (error) {
                console.error('Error processing message:', error);
            }
        });

        sendMessageToNative(WebAction.ConnectionStart);

        return () => {
            sendMessageToNative(WebAction.ConnectionStop);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return children;
}
