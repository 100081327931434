import { createSlice } from '@reduxjs/toolkit';

export interface AuthState {
    bearerToken: string | null;
}

const initialState: AuthState = {
    bearerToken: null,
};

export const authSlice = createSlice({
    name: 'authConfig',
    initialState,
    reducers: {
        setBearerToken: (state, { payload }) => {
            state.bearerToken = payload;
        },
    },
    selectors: {
        selectBearerToken: (state: AuthState) => state.bearerToken,
    },
});

export const { setBearerToken } = authSlice.actions;

export const { selectBearerToken } = authSlice.selectors;

export default authSlice.reducer;
