import { useContext } from 'react';
import { useSelector } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { faCalendarXmark, faCalendarCheck } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Box, Stack, Button, ButtonProps } from '@mui/material';
import Typography from '@mui/material/Typography';

import { CheckInStatuses } from '../../constants/CheckIn';
import { Drawers } from '../../constants/Drawers';
import { DrawerContext, OnNext } from '../drawers/DrawerManager';
import { AnalyticsContext } from 'src/AnalyticsContext';
import useCheckIns from 'src/hooks/useCheckIns';
import { selectIsIndependentLiving } from 'src/store/configSlice';
import { CheckIn, Unit, useUpdateCheckInMutation } from 'src/store/sageAdminApi';
import { snackActions } from 'src/utils/snackBarTools';

import '../../assets/styles/buttonStyles.scss';

interface UnitCtaButtonsProps {
    analyticsPrefix?: string;
    checkInButtonProps?: ButtonProps;
    onClose?: () => void;
    onNext?: OnNext;
    showEmptyMessage?: boolean;
    unit: Unit & { checkIn?: CheckIn };
}

const UnitCtaButtons = (props: UnitCtaButtonsProps) => {
    const {
        analyticsPrefix,
        checkInButtonProps = {},
        onClose,
        onNext,
        showEmptyMessage = false,
        unit,
    } = props;
    const isIndependentLiving = useSelector(selectIsIndependentLiving);
    const unitCheckedIn = isIndependentLiving && unit?.checkIn?.status === CheckInStatuses.Complete;
    const { trackEvent } = useContext(AnalyticsContext);
    const { openDrawer } = useContext(DrawerContext);

    const [updateCheckIn, { isLoading: isUpdatingCheckIn }] = useUpdateCheckInMutation();
    const unitNotCheckedIn =
        isIndependentLiving && unit?.checkIn?.status === CheckInStatuses.Pending;

    const { isDuringDowntime } = useCheckIns();

    const handleCheckInUnit = () => {
        trackEvent('Check-in Unit Button Clicked', { analyticsPrefix });

        if (onNext) {
            // we are in the unit detail drawer
            onNext({ unit }, { type: Drawers.UnitCheckIn });
        } else {
            // we are on the unit detail page
            openDrawer({ type: Drawers.UnitCheckIn, props: { unit } });
        }
    };

    const handleRemoveCheckIn = async () => {
        trackEvent('Remove Check-in Button Clicked', { analyticsPrefix, unitId: unit?.id });

        try {
            await updateCheckIn({
                checkInId: unit.checkIn!.id!,
                updateCheckInRequest: {
                    status: CheckInStatuses.Pending,
                },
            }).unwrap();

            snackActions.success(`Check-in updated for unit ${unit.displayName}`);
            if (onClose) {
                onClose();
            }
        } catch (error) {
            datadogRum.addError(error, {
                message: 'Error removing check-in',
                unitId: unit?.id,
            });
            snackActions.error('Error updating check in, please try again');
        }
    };

    const handleCancel = () => {
        trackEvent('Cancel Button Clicked', { analyticsPrefix });
        onClose?.();
    };

    return (
        <Stack
            sx={{
                gap: 2.5,
            }}
        >
            {(unit?.currentOccupants?.length || 0) > 0 && (
                <>
                    {unitNotCheckedIn && (
                        <Button
                            fullWidth
                            disableElevation
                            variant="contained"
                            endIcon={<FontAwesomeIcon icon={faCalendarCheck} />}
                            color="primary"
                            onClick={handleCheckInUnit}
                            disabled={isDuringDowntime}
                            {...checkInButtonProps}
                        >
                            <Typography
                                variant="body1"
                                sx={{
                                    fontWeight: 'bold',
                                }}
                            >
                                Check-in Unit
                            </Typography>
                        </Button>
                    )}

                    {isDuringDowntime && (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                            }}
                        >
                            <Typography variant="subtitle1">
                                Daily check-in begins at <strong>4:00 am</strong>
                            </Typography>
                        </Box>
                    )}

                    {unitCheckedIn && (
                        <Button
                            fullWidth
                            disableElevation
                            variant="outlined"
                            endIcon={<FontAwesomeIcon icon={faCalendarXmark} />}
                            color="error"
                            onClick={handleRemoveCheckIn}
                            loading={isUpdatingCheckIn}
                        >
                            <Typography sx={{ fontWeight: 'bold' }}>
                                Remove Unit Check-in
                            </Typography>
                        </Button>
                    )}

                    {onClose && (
                        <Button fullWidth disableElevation onClick={handleCancel}>
                            Cancel
                        </Button>
                    )}
                </>
            )}
            {!unit?.currentOccupants?.length && showEmptyMessage && (
                <>
                    <Box sx={{ pt: 3, pb: 2.5 }}>
                        <Typography
                            variant="subtitle1"
                            component="p"
                            align="center"
                            sx={{ mb: 1.5 }}
                        >
                            No residents in this unit
                        </Typography>
                    </Box>

                    <Button fullWidth disableElevation onClick={handleCancel} className="ctaButton">
                        Go Back
                    </Button>
                </>
            )}
        </Stack>
    );
};

export default UnitCtaButtons;
