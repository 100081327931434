import { useContext, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

import { datadogLogs } from '@datadog/browser-logs';
import { datadogRum } from '@datadog/browser-rum';
import {
    faArrowRight,
    faPhoneAlt,
    faStar,
    faTimes,
    faTrashAlt,
} from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Dialog, DialogActions, DialogContent, Typography } from '@mui/material';

import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import Groups from 'src/constants/Groups';
import useUserPermissions from 'src/hooks/useUserPermissions';
import { selectFacilityId, selectStaffMemberId } from 'src/store/configSlice';
import {
    Group,
    StaffMemberFacilityConfig,
    useGetCompanyPhoneQuery,
    useGetMembershipDetailsQuery,
    useGetStaffMemberFacilityMetadataQuery,
    useGetStaffMemberQuery,
    useRemoveMemberFromGroupMutation,
    useUpdateStaffMemberFacilityConfigMutation,
} from 'src/store/sageAdminApi';
import { useUpdateGroupMembershipMutation } from 'src/store/sageAdminApi';
import { sageBaseAdminApi } from 'src/store/sageBaseAdminApi';
import { snackActions } from 'src/utils/snackBarTools';

import '../../assets/styles/containerStyles.scss';

interface StaffMemberActionsDrawerProps extends DrawerComponentProps {
    staffMemberId: string;
    group: Group;
}

function StaffMemberActionsDrawer(props: StaffMemberActionsDrawerProps) {
    const { onNext, onClose, staffMemberId, group } = props;

    const { name: groupName, id: groupId = '' } = group || {};

    const { trackEvent } = useContext(AnalyticsContext);

    const dispatch = useDispatch();
    const facilityId = useSelector(selectFacilityId);
    const currentUserProfileId = useSelector(selectStaffMemberId);

    const [showConfirmRemovalDialog, setShowConfirmRemovalDialog] = useState(false);

    const { canSeePII: userCanSeePII, isManagementGroupAdmin: currentUserIsManagementAdmin } =
        useUserPermissions();

    const { data: staffMember, isLoading: isLoadingStaffMember } = useGetStaffMemberQuery(
        { staffMemberId },
        { skip: !staffMemberId }
    );

    const staffMemberFullName = `${staffMember?.firstName} ${staffMember?.lastName}`;

    const { data: staffFacilityMetadata, isLoading: isLoadingStaffMemberGroups } =
        useGetStaffMemberFacilityMetadataQuery(
            { staffMemberId, facilityId },
            { skip: !facilityId || !staffMemberId || !!groupId }
        );

    const taskGroupId = useMemo(
        () => groupId || staffFacilityMetadata?.[0]?.groups?.[0].groupId,
        [groupId, staffFacilityMetadata]
    );

    const facilityOnCallState = useMemo(
        () => staffMember?.onCallStates?.find((state) => state.facilityId === facilityId),
        [facilityId, staffMember]
    );

    const { data: companyPhone, isLoading: isLoadingCompanyPhone } = useGetCompanyPhoneQuery(
        { companyPhoneId: facilityOnCallState?.companyPhoneId ?? '' },
        { skip: !facilityOnCallState || !facilityOnCallState.companyPhoneId }
    );

    const isUsingCompanyPhone = facilityOnCallState?.isOnCall && !!companyPhone;

    const { data: groupMembers, isLoading: isLoadingGroupMembers } = useGetMembershipDetailsQuery(
        { groupId },
        { skip: !groupId }
    );

    const groupAdmins = useMemo(
        () => groupMembers?.filter((member) => member.isAdmin),
        [groupMembers]
    );

    const staffMemberIsGroupAdmin = useMemo(
        () => groupAdmins?.some((member) => member.staffMember?.id === staffMemberId),
        [groupAdmins, staffMemberId]
    );

    const currentUserIsGroupAdmin = useMemo(
        () => groupAdmins?.some((member) => member.staffMember?.id === currentUserProfileId),
        [groupAdmins, currentUserProfileId]
    );

    const canModifyGroupMembership = currentUserIsManagementAdmin || currentUserIsGroupAdmin;
    const canRemoveAsAdmin = canModifyGroupMembership && (groupAdmins?.length || 0) > 1;

    const handleTrackButtonClick = (eventName: string) => {
        trackEvent(eventName, { objectType: ObjectTypes.Button });
        onNext();
    };

    const [removeMemberFromGroup, { isLoading: isRemovingMemberFromGroup }] =
        useRemoveMemberFromGroupMutation();
    const [updateGroupMembership, { isLoading: isUpdatingGroupMembership }] =
        useUpdateGroupMembershipMutation();
    const [updateStaffMemberFacilityConfig, { isLoading: isUpdatingStaffMember }] =
        useUpdateStaffMemberFacilityConfigMutation();

    const handleMakeGroupAdmin = async () => {
        trackEvent('Make Group Admin Clicked', {
            groupName: groupName,
            newAdminStatus: !staffMemberIsGroupAdmin,
        });

        try {
            await updateGroupMembership({
                groupId,
                createOrUpdateGroupMembershipRequest: { staffMemberId, isAdmin: true },
            }).unwrap();

            snackActions.success(`${staffMemberFullName} was made a ${groupName} group admin`);

            onNext();
        } catch (error) {
            datadogRum.addError(error, {
                message: 'Failed to make group admin',
                groupName,
                groupId,
                staffMemberId,
            });
            snackActions.error(`Failed to make ${staffMemberFullName} a ${groupName} group admin`);
        }
    };

    const handleDismissAsGroupAdmin = async () => {
        trackEvent('Dismiss As Group Admin Clicked', {
            groupName: groupName,
            newAdminStatus: !staffMemberIsGroupAdmin,
        });

        try {
            await updateGroupMembership({
                groupId,
                createOrUpdateGroupMembershipRequest: { staffMemberId, isAdmin: false },
            }).unwrap();

            snackActions.success(`${staffMemberFullName} is no longer a ${groupName} admin`);

            onNext();
        } catch (error) {
            datadogRum.addError(error, {
                message: 'Failed to toggle group admin status',
                groupName,
                groupId,
                staffMemberId,
            });
            snackActions.error(
                `Failed to dismiss ${staffMemberFullName} as a ${groupName} group admin`
            );
        }
    };

    const handleRemoveTeamMemberFromGroup = () => {
        trackEvent('Remove Team Member Clicked', { groupName: groupName });
        setShowConfirmRemovalDialog(true);
    };

    const handleConfirmRemoveTeamMemberFromGroup = async () => {
        trackEvent('Confirm Remove Team Clicked', { groupName: groupName });

        setShowConfirmRemovalDialog(false);

        if (!staffMember) {
            datadogLogs.logger.warn('Attempting to remove no profile!', {
                groupName: groupName,
                groupId: group.id,
            });
            return;
        }

        const { firstName, lastName, id } = staffMember;

        try {
            // Update the staff member first before removing them from the group to avoid
            // the case where a staff member is removed from all groups in the facility and
            // the user no longer has permission to update the removed staff member profile.
            if (groupName === Groups.CareStaff) {
                const staffMemberFacilityConfig = staffMember.staffMemberFacilityConfigs?.find(
                    (config: StaffMemberFacilityConfig) => config.facilityId === facilityId
                );

                if (staffMemberFacilityConfig) {
                    await updateStaffMemberFacilityConfig({
                        staffMemberFacilityConfigId: staffMemberFacilityConfig.id!,
                        createOrUpdateStaffMemberFacilityConfigRequest: {
                            shouldReceiveAllCareAlerts: false,
                            shouldReceiveAllCareMessages: false,
                        },
                    });
                }
            }

            if (!!groupId && !!id) {
                await removeMemberFromGroup({ groupId, staffMemberId: id }).unwrap();
                dispatch(sageBaseAdminApi.util.invalidateTags(['staff']));

                snackActions.success(`${firstName} ${lastName} was removed from ${groupName}`);
                onNext();
            }
        } catch (error) {
            datadogRum.addError(error, {
                message: 'Failed to remove staff member from group',
                groupName,
                groupId,
                staffMemberId: id,
            });
            snackActions.error(`Failed to remove ${firstName} ${lastName} from ${groupName}`);
        }
    };

    const handleCancelRemoveTeamMemberFromGroup = () => {
        trackEvent('Cancel Remove Team Clicked', { groupName });
        setShowConfirmRemovalDialog(false);
    };

    const isLoading =
        isLoadingStaffMember ||
        isLoadingStaffMemberGroups ||
        isLoadingGroupMembers ||
        isLoadingCompanyPhone;

    const shouldDisableAllActions =
        isLoading ||
        isRemovingMemberFromGroup ||
        isUpdatingGroupMembership ||
        isUpdatingStaffMember;

    return (
        <>
            <DrawerTitle
                title={staffMember ? `${staffMember?.firstName} ${staffMember?.lastName}` : ''}
            />

            <DrawerFooter sx={{ gap: 2 }}>
                {staffMemberId && (
                    <Button
                        color="primary"
                        component={Link}
                        disabled={shouldDisableAllActions}
                        disableElevation
                        endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                        fullWidth
                        onClick={() => handleTrackButtonClick('Assign Task Clicked')}
                        state={{
                            assigneeId: staffMemberId,
                            groupId: taskGroupId,
                        }}
                        to="/tasks/add"
                        variant="contained"
                    >
                        Assign task
                    </Button>
                )}

                {isUsingCompanyPhone ? (
                    <Button
                        color="primary"
                        disableElevation
                        disabled={shouldDisableAllActions}
                        fullWidth
                        href={`tel:${companyPhone?.phoneNumber}`}
                        onClick={() => handleTrackButtonClick('Call Company Phone Clicked')}
                        endIcon={<FontAwesomeIcon icon={faPhoneAlt} />}
                        variant="outlined"
                    >
                        Call ({companyPhone.displayName})
                    </Button>
                ) : (
                    userCanSeePII && (
                        <Button
                            color="primary"
                            disableElevation
                            disabled={shouldDisableAllActions}
                            fullWidth
                            href={`tel:${staffMember?.phoneNumber}`}
                            onClick={() => handleTrackButtonClick('Call Personal Phone Clicked')}
                            endIcon={<FontAwesomeIcon icon={faPhoneAlt} />}
                            variant="outlined"
                        >
                            Call Personal Phone
                        </Button>
                    )
                )}

                <Button
                    color="primary"
                    component={Link}
                    disableElevation
                    disabled={shouldDisableAllActions || !staffMember?.id}
                    endIcon={<FontAwesomeIcon icon={faArrowRight} />}
                    fullWidth
                    onClick={() => handleTrackButtonClick('View Profile Clicked')}
                    to={`/team/member/${staffMember?.id}`}
                    variant="outlined"
                >
                    View Profile
                </Button>
                {group &&
                    canModifyGroupMembership &&
                    (!staffMemberIsGroupAdmin ? (
                        <Button
                            color="primary"
                            disableElevation
                            disabled={shouldDisableAllActions}
                            endIcon={<FontAwesomeIcon icon={faStar} />}
                            fullWidth
                            loading={isUpdatingGroupMembership}
                            onClick={handleMakeGroupAdmin}
                            variant="outlined"
                        >
                            Make Group Admin
                        </Button>
                    ) : (
                        canRemoveAsAdmin && (
                            <Button
                                color="primary"
                                disableElevation
                                disabled={shouldDisableAllActions}
                                endIcon={<FontAwesomeIcon icon={faTimes} />}
                                fullWidth
                                loading={isUpdatingGroupMembership}
                                onClick={handleDismissAsGroupAdmin}
                                variant="outlined"
                            >
                                Dismiss as Group Admin
                            </Button>
                        )
                    ))}

                {group && canModifyGroupMembership && (
                    <Button
                        color="error"
                        disabled={shouldDisableAllActions}
                        disableElevation
                        endIcon={<FontAwesomeIcon icon={faTrashAlt} />}
                        fullWidth
                        loading={isRemovingMemberFromGroup}
                        onClick={handleRemoveTeamMemberFromGroup}
                        variant="outlined"
                    >
                        Remove Team Member
                    </Button>
                )}

                <Button color="primary" disableElevation fullWidth onClick={onClose}>
                    Cancel
                </Button>
            </DrawerFooter>

            <Dialog open={showConfirmRemovalDialog} onClose={handleCancelRemoveTeamMemberFromGroup}>
                <DialogContent className="dialogContentContainer">
                    <Typography variant="h4">
                        Are you sure you want to remove{' '}
                        <b>{`${staffMember?.firstName} ${staffMember?.lastName}`}</b> from the{' '}
                        {groupName} group?
                    </Typography>
                </DialogContent>
                <DialogActions className="dialogButtonContainer">
                    <Button
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={handleConfirmRemoveTeamMemberFromGroup}
                        variant="outlined"
                    >
                        Yes
                    </Button>
                    <Button
                        color="primary"
                        disableElevation
                        fullWidth
                        onClick={handleCancelRemoveTeamMemberFromGroup}
                        variant="contained"
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
}

export default StaffMemberActionsDrawer;
