import { combineReducers, configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react';

import authReducer from './authSlice';
import configReducer from './configSlice';
import globalUIReducer from './globalUISlice';
import reactNativeReducer from './reactNativeSlice';
import { sageAdminApi } from './sageAdminApi';
import { sageCareServiceApi } from './sageCareServiceApi';
import { sageGamificationApi } from './sageGamificationApi';
import { sageInsightsEngineApi } from './sageInsightsEngineApi';
import { sageLocationQueryApi } from './sageLocationQueryApi';
import { sageMessageOrchestrationApi } from './sageMessageOrchestrationApi';
import { sageTabularRecordServiceApi } from './sageTabularRecordServiceApi';

// Create the root reducer separately so we can extract the RootState type
const rootReducer = combineReducers({
    [sageAdminApi.reducerPath]: sageAdminApi.reducer,
    [sageCareServiceApi.reducerPath]: sageCareServiceApi.reducer,
    [sageGamificationApi.reducerPath]: sageGamificationApi.reducer,
    [sageInsightsEngineApi.reducerPath]: sageInsightsEngineApi.reducer,
    [sageLocationQueryApi.reducerPath]: sageLocationQueryApi.reducer,
    [sageMessageOrchestrationApi.reducerPath]: sageMessageOrchestrationApi.reducer,
    [sageTabularRecordServiceApi.reducerPath]: sageTabularRecordServiceApi.reducer,
    appConfig: configReducer,
    authConfig: authReducer,
    globalUI: globalUIReducer,
    reactNative: reactNativeReducer,
});

export const setupStore = (preloadedState?: Partial<RootState>) => {
    return configureStore({
        reducer: rootReducer,
        preloadedState,
        middleware: (getDefaultMiddleware) =>
            getDefaultMiddleware().concat(
                sageAdminApi.middleware,
                sageCareServiceApi.middleware,
                sageGamificationApi.middleware,
                sageInsightsEngineApi.middleware,
                sageLocationQueryApi.middleware,
                sageMessageOrchestrationApi.middleware,
                sageTabularRecordServiceApi.middleware
            ),
    });
};

export const store = setupStore();

setupListeners(store.dispatch);

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore['dispatch'];
