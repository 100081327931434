import { ErrorBoundary } from 'react-error-boundary';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';

import { Auth0Provider } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { CssBaseline } from '@mui/material';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';

import AnalyticsContextProvider from './AnalyticsContext';
import App from './App';
import CheckCookiesEnabled from './CheckCookiesEnabled';
import ReactNativeContainer from './ReactNativeContainer';
import SageTheme from './assets/styles/sageTheme';
import DrawerManager from './components/drawers/DrawerManager';
import { authorizationParams } from './constants/Auth0';
import NotificationsContextProvider from './contexts/NotificationsContext';
// Datadog RUM and Datadog Logs are initialized inside the import
import './datadog';
import ErrorPage from './pages/ErrorPage';
import { store } from './store';
import { createRoot } from 'react-dom/client';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);

// Make sure errors are picked up by Datadog
const logError = (error: any, info: any) => {
    datadogLogs.logger.error('Error caught by error boundry', { info }, error);
};

root.render(
    <BrowserRouter>
        <Provider store={store}>
            <CssBaseline>
                <StyledEngineProvider injectFirst>
                    <ThemeProvider theme={SageTheme}>
                        <ErrorBoundary fallbackRender={ErrorPage} onError={logError}>
                            <CheckCookiesEnabled>
                                <ReactNativeContainer>
                                    <Auth0Provider
                                        domain={import.meta.env.VITE_AUTH0_DOMAIN}
                                        clientId={import.meta.env.VITE_AUTH0_CLIENT_ID}
                                        authorizationParams={authorizationParams}
                                        cacheLocation={import.meta.env.VITE_AUTH0_STORAGE_LOCATION}
                                        useRefreshTokens
                                        // See: https://community.auth0.com/t/auth0-spa-2-x-returning-missing-refresh-token/98999/18
                                        useRefreshTokensFallback
                                    >
                                        <AnalyticsContextProvider>
                                            <NotificationsContextProvider>
                                                <DrawerManager>
                                                    <App />
                                                </DrawerManager>
                                            </NotificationsContextProvider>
                                        </AnalyticsContextProvider>
                                    </Auth0Provider>
                                </ReactNativeContainer>
                            </CheckCookiesEnabled>
                        </ErrorBoundary>
                    </ThemeProvider>
                </StyledEngineProvider>
            </CssBaseline>
        </Provider>
    </BrowserRouter>
);
