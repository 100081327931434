import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { Button } from '@mui/material';

import CareZoneSelection from '../fragments/careZoneSelection';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import useCareZones from 'src/hooks/useCareZones';
import {
    selectFacilityId,
    selectStaffMemberFacilityConfigs,
    selectStaffMemberId,
} from 'src/store/configSlice';
import {
    useCreateStaffMemberFacilityConfigMutation,
    useUpdateStaffMemberFacilityConfigMutation,
} from 'src/store/sageAdminApi';
import { snackActions } from 'src/utils/snackBarTools';

const DrawerName = 'Select Care Zone Drawer';

function SelectCareZoneDrawer(props: DrawerComponentProps) {
    const { onClose = () => {}, onNext = () => {} } = props;

    const { trackEvent } = useContext(AnalyticsContext);

    const staffMemberId = useSelector(selectStaffMemberId);
    const facilityId = useSelector(selectFacilityId);
    const staffMemberFacilityConfigs = useSelector(selectStaffMemberFacilityConfigs);

    const { careZones, careZonesWithUnits, isLoading: isLoadingCareZones } = useCareZones();

    const [createStaffMemberFacilityConfig, { isLoading: isCreatingConfig }] =
        useCreateStaffMemberFacilityConfigMutation();
    const [updateStaffMemberFacilityConfig, { isLoading: isUpdatingConfig }] =
        useUpdateStaffMemberFacilityConfigMutation();

    const [selectedCareZoneIds, setSelectedCareZoneIds] = useState<string[]>();

    const isProcessing = isCreatingConfig || isUpdatingConfig;
    const isDisabled = (selectedCareZoneIds?.length || 0) < 1 || isProcessing;

    useEffect(() => {
        if (!isLoadingCareZones && !selectedCareZoneIds && staffMemberFacilityConfigs?.careZones) {
            const filteredCareZoneIds = staffMemberFacilityConfigs.careZones
                .map((careZone) => careZone.id!)
                .filter((id) => careZonesWithUnits.some((careZone) => careZone.id === id));

            setSelectedCareZoneIds(filteredCareZoneIds);
        }
    }, [careZonesWithUnits, isLoadingCareZones, selectedCareZoneIds, staffMemberFacilityConfigs]);

    const handleUpdateCareZones = async () => {
        trackEvent('Care Zones Confirmed', {
            objectType: ObjectTypes.Button,
            context: DrawerName,
            selectedCareZones: selectedCareZoneIds?.map(
                (id) => careZones?.find((careZone) => careZone.id === id)?.name
            ),
        });

        try {
            const requestBody = {
                ...(staffMemberFacilityConfigs || {}),
                careZoneIds: selectedCareZoneIds,
                facilityId,
                staffMemberId,
            };

            if (!!staffMemberFacilityConfigs?.id) {
                const current =
                    staffMemberFacilityConfigs.careZones?.map?.((careZone) => careZone.id) || [];
                if (!isEqual(current.sort(), selectedCareZoneIds?.sort?.())) {
                    await updateStaffMemberFacilityConfig({
                        staffMemberFacilityConfigId: staffMemberFacilityConfigs.id,
                        createOrUpdateStaffMemberFacilityConfigRequest: pick(requestBody, [
                            'careZoneIds',
                            'facilityId',
                            'id',
                            'staffMemberId',
                        ]),
                    }).unwrap();
                }
            } else {
                await createStaffMemberFacilityConfig({
                    createOrUpdateStaffMemberFacilityConfigRequest: pick(requestBody, [
                        'careZoneIds',
                        'facilityId',
                        'staffMemberId',
                    ]),
                }).unwrap();
            }

            onNext();
        } catch (error) {
            datadogRum.addError(error, {
                message: 'Error updating care zones',
                staffMemberId,
                facilityId,
                selectedCareZoneIds,
            });
            snackActions.error('There was an error updating your preferences, please try again.');
        }
    };

    return (
        <>
            <DrawerTitle title="Working In" />

            <DrawerBody>
                <CareZoneSelection
                    isDisabled={isProcessing}
                    maxHeight={380}
                    selectedIds={selectedCareZoneIds}
                    setSelectedIds={setSelectedCareZoneIds}
                    shouldPoll
                />
            </DrawerBody>

            <DrawerFooter sx={{ gap: 1.5 }}>
                <Button
                    color="primary"
                    disableElevation
                    disabled={isDisabled}
                    fullWidth
                    loading={isProcessing}
                    onClick={handleUpdateCareZones}
                    variant="contained"
                >
                    Confirm Selection
                </Button>
                <Button
                    color="primary"
                    disableElevation
                    disabled={isDisabled}
                    fullWidth
                    onClick={onClose}
                    variant="text"
                >
                    Cancel
                </Button>
            </DrawerFooter>
        </>
    );
}

export default SelectCareZoneDrawer;
