import { sageBaseLocationQueryApi as api } from './sageBaseLocationQueryApi';

export const addTagTypes = ['locationQuery'] as const;
const injectedRtkApi = api
    .enhanceEndpoints({
        addTagTypes,
    })
    .injectEndpoints({
        endpoints: (build) => ({
            getTaskLocation: build.query<GetTaskLocationApiResponse, GetTaskLocationApiArg>({
                query: (queryArg) => ({
                    url: `/locationQuery/${queryArg.taskRid}`,
                    headers: {
                        Authorization: queryArg?.authorization,
                        'x-sage-profile-rid': queryArg?.['x-sage-profile-rid'],
                    },
                    params: { deviceRid: queryArg.deviceRid },
                }),
                providesTags: ['locationQuery'],
            }),
        }),
        overrideExisting: false,
    });
export { injectedRtkApi as sageLocationQueryApi };
export type GetTaskLocationApiResponse = /** status 200 OK */ {
    confidence: string;
    /** The scores of the models used to infer the location, descending order from highest score to lowest */
    modelScores: {
        unitRid?: string;
        unitDisplayName?: string;
        value?: number;
        adjacencyMatrix?: {
            unit?: {
                unitRid?: string;
                unitName?: string;
            };
            adjacentUnits?: AdjacencyUnit[];
        }[];
    }[];
    /** The inferred location based on the nearest receiver */
    nearestReceiverLocation: string;
    /** The inferred location based on the location neural network model */
    nearestUnitLocation?: string;
    /** The timestamp of the latest update to the location inference */
    lastUpdateTimestampInEpochMs: number;
};
export type GetTaskLocationApiArg = {
    authorization?: string;
    'x-sage-profile-rid'?: string;
    taskRid: string;
    deviceRid?: string;
};
export type AdjacencyUnit = {
    unitRid?: string;
    unitName?: string;
};
export const { useGetTaskLocationQuery, useLazyGetTaskLocationQuery } = injectedRtkApi;
