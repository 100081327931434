import { useContext, useState } from 'react';
import React from 'react';
import { useDispatch } from 'react-redux';

import { datadogRum } from '@datadog/browser-rum';
import { faCircleInfo } from '@fortawesome/pro-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography, Button, FormControl, Stack, TextField, Box } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import { AnalyticsContext, ObjectTypes } from '../../AnalyticsContext';
import { ActionTypes } from '../../constants/InsightTypes';
import { sageBaseInsightsEngineApi } from '../../store/sageBaseInsightsEngineApi';
import { useExecuteActionMutation } from '../../store/sageInsightsEngineApi';
import { snackActions } from '../../utils/snackBarTools';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';

interface SendHighFiveDrawerProps extends DrawerComponentProps {
    workflowRid: string;
    insightRid: string;
}

const DrawerName = 'Send High Five Drawer';
function SendHighFiveDrawer(props: SendHighFiveDrawerProps) {
    const { onClose, onNext, title = 'Send a high five', workflowRid, insightRid } = props;
    const [notes, setNotes] = useState('');
    const [executeRecognitionAction, { isLoading: executingRecognitionAction }] =
        useExecuteActionMutation();
    const theme = useTheme();
    const dispatch = useDispatch();

    const { trackEvent } = useContext(AnalyticsContext);

    const shouldDisableActions = executingRecognitionAction;

    const handleGoBack = () => {
        trackEvent('Go Back Clicked', {
            objectType: ObjectTypes.Button,
            buttonLabel: 'Go Back',
            context: DrawerName,
        });
        onClose();
    };

    const sendHighFive = async () => {
        try {
            trackEvent('Insight Submitted', {
                objectType: ObjectTypes.Button,
                buttonLabel: 'Submit',
                context: DrawerName,
                notesLength: notes?.length || 0,
                actionType: ActionTypes.SendHighFive,
                workflowRid,
                insightRid,
            });
            await executeRecognitionAction({
                workflowRid,
                executeActionRequest: {
                    type: ActionTypes.SendHighFive,
                    note: notes,
                },
            }).unwrap();
            snackActions.success('High Five Sent!');
            onNext();
        } catch (error: any) {
            datadogRum.addError(error, {
                message: 'Error sending high five',
                workflowRid,
                insightRid,
            });

            const errorMessage =
                error.data?.code === 409
                    ? 'The insight has been closed.  No further action can be taken.'
                    : 'Error sending high five';

            snackActions.error(errorMessage);

            dispatch(
                sageBaseInsightsEngineApi.util.invalidateTags(['insights', 'workflows', 'actions'])
            );
            onClose();
        }
    };

    const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        sendHighFive();
    };

    return (
        <>
            <DrawerTitle sx={{ gap: 1 }}>
                <Typography
                    sx={{
                        fontSize: 48,
                        lineHeight: 1,
                    }}
                >
                    ✋
                </Typography>
                <Typography
                    variant="h4"
                    sx={{
                        fontWeight: 'bold',
                    }}
                >
                    {title}
                </Typography>
                <Stack
                    direction="row"
                    sx={{
                        bgcolor: 'purple.100',
                        gap: 1,
                        p: 1,
                        borderRadius: 2,
                    }}
                >
                    <Box
                        sx={{
                            lineHeight: 1.5,
                        }}
                    >
                        <FontAwesomeIcon icon={faCircleInfo} color={theme.palette.primary.main} />
                    </Box>

                    <Typography variant="body2">
                        Every team member can see high five messages.
                    </Typography>
                </Stack>
            </DrawerTitle>
            <form onSubmit={handleSubmit}>
                <DrawerBody>
                    <FormControl fullWidth>
                        <TextField
                            id="notes"
                            label="Add a custom message (optional)"
                            multiline
                            rows={4}
                            variant="outlined"
                            value={notes}
                            disabled={shouldDisableActions}
                            onChange={({ target }) => setNotes(target.value)}
                        />
                    </FormControl>
                </DrawerBody>

                <DrawerFooter sx={{ gap: 2.5 }}>
                    <Button
                        fullWidth
                        color="primary"
                        type="submit"
                        variant="contained"
                        loading={shouldDisableActions}
                        disabled={shouldDisableActions}
                        disableElevation
                    >
                        {!shouldDisableActions && 'Submit'}
                    </Button>

                    <Button color="primary" disableElevation fullWidth onClick={handleGoBack}>
                        Go Back
                    </Button>
                </DrawerFooter>
            </form>
        </>
    );
}

export default SendHighFiveDrawer;
