import { SyntheticEvent, useContext, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { datadogRum } from '@datadog/browser-rum';
import { Button, FormControl, TextField } from '@mui/material';

import { selectEnterpriseId, selectFacilityId, selectStaffMemberId } from '../../store/configSlice';
import DrawerBody from './DrawerBody';
import DrawerFooter from './DrawerFooter';
import { DrawerComponentProps } from './DrawerManager';
import DrawerTitle from './DrawerTitle';
import { AnalyticsContext, ObjectTypes } from 'src/AnalyticsContext';
import useFacilityGroups from 'src/hooks/useFacilityGroups';
import {
    AlertOutcomeType,
    Resident,
    Task,
    useCreateResidentActivityMutation,
    useGetResidentQuery,
    useResolveTaskMutation,
} from 'src/store/sageAdminApi';
import { CareProvided } from 'src/store/sageCareServiceApi';
import { snackActions } from 'src/utils/snackBarTools';

import '../../assets/styles/buttonStyles.scss';

const CharacterLimit = 255;

interface ResolveTaskDrawerProps extends DrawerComponentProps {
    analyticsPrefix?: string;
    bypassCareConfirmationChecks?: boolean;
    isEmergency: boolean;
    redirectOnSuccess?: boolean;
    redirectPath?: string;
    residentId?: string;
    resident?: Resident;
    resolutionTypes: CareProvided[];
    alertOutcomeType: AlertOutcomeType;
    showCancelAction?: boolean;
    submitButtonLabel?: string;
    successMessage?: string;
    task: Task;
}

function ResolveTaskDrawer(props: ResolveTaskDrawerProps) {
    const {
        analyticsPrefix,
        bypassCareConfirmationChecks = false,
        isEmergency,
        onClose,
        onNext,
        onPrevious,
        redirectOnSuccess = true,
        redirectPath = '/dashboard',
        residentId,
        resident,
        resolutionTypes,
        alertOutcomeType,
        showCancelAction = true,
        submitButtonLabel = 'Submit',
        successMessage = 'Emergency Resolved',
        task,
        title = 'Additional Notes',
    } = props;

    const navigate = useNavigate();
    const { trackEvent } = useContext(AnalyticsContext);

    const enterpriseId = useSelector(selectEnterpriseId);
    const facilityId = useSelector(selectFacilityId);
    const staffMemberId = useSelector(selectStaffMemberId);

    const { careGroup } = useFacilityGroups();

    const { data: residentFromServer, isLoading: isLoadingResident } = useGetResidentQuery(
        { residentId: residentId || resident?.id || '' },
        { skip: !!resident || !residentId }
    );

    const residentToUse = resident || residentFromServer;

    const [createResidentActivity, { isLoading: isCreatingResidentActivity }] =
        useCreateResidentActivityMutation();
    const [resolveTask, { isLoading: isResolvingTask }] = useResolveTaskMutation();

    const isLoggingCare = !task || !task.id;
    const shouldDisableActions =
        isLoadingResident ||
        isResolvingTask ||
        isCreatingResidentActivity ||
        (isLoggingCare && !residentToUse);

    const [notes, setNotes] = useState('');
    const isOverCharacterLimit = (notes?.length ?? 0) > CharacterLimit;
    const difference = Math.abs((notes?.length ?? 0) - CharacterLimit);

    const handleGoBack = () => {
        trackEvent('Go Back Clicked', {
            objectType: ObjectTypes.Button,
            buttonLabel: 'Go Back',
            context: analyticsPrefix,
        });

        // TODO(@robsli): look into revamping this logic to make it less error-prone!
        // prevents the current resolutionType from overriding a new selection (see DrawerManager.handlePreviousDrawer)
        const propsToOmit = ['resolutionTypes', 'alertOutcomeType'];
        onPrevious(true, propsToOmit);
    };

    const handleSuccess = () => {
        snackActions.success(successMessage);
        if (redirectOnSuccess) {
            navigate(redirectPath);
        }
        onNext();
    };

    const handleError = (
        error: any,
        toastMessage = 'There was an error resolving this task. Please try again.'
    ) => {
        datadogRum.addError(error, {
            message: 'Error resolving task',
            taskId: task?.id,
            residentId: residentToUse?.id,
        });
        snackActions.error(toastMessage);
    };

    const addResidentActivity = async () => {
        try {
            const { rid: taskId } = await createResidentActivity({
                createResidentActivityRequest: {
                    creatorId: staffMemberId,
                    enterpriseId,
                    facilityId,
                    groupId: careGroup?.id ?? '',
                    residentId,
                    resolutionTypes: resolutionTypes,
                    unitId: residentToUse?.unit?.id,
                    resolutionNote: notes,
                },
            }).unwrap();

            trackEvent('Resident Activity Added', {
                taskId: taskId,
                objectType: ObjectTypes.Button,
                notesLength: notes?.length || 0,
                buttonLabel: submitButtonLabel,
                context: analyticsPrefix,
            });
            handleSuccess();
        } catch (error) {
            handleError(error, 'There was an error reporting this emergency. Please try again.');
        }
    };

    const handleResolveTask = async () => {
        try {
            await resolveTask({
                taskId: task?.id!,
                resolveTaskRequest: {
                    resolutionTypes: resolutionTypes,
                    alertOutcomeType: alertOutcomeType,
                    resolutionNote: notes,
                    residentId: residentToUse?.id,
                    bypassCareConfirmationChecks,
                },
            }).unwrap();

            const eventName = isEmergency ? 'Alert Resolved' : 'Task Resolved';
            trackEvent(eventName, {
                objectType: ObjectTypes.Button,
                notesLength: notes?.length || 0,
                buttonLabel: submitButtonLabel,
                context: analyticsPrefix,
            });

            handleSuccess();
        } catch (error: any) {
            if (error?.data?.code === 409) {
                snackActions.error(
                    `This ${isEmergency ? 'alert' : 'task'} has already been resolved.`
                );
                onClose();
            } else {
                handleError(error);
            }
        }
    };

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault();

        trackEvent('Submit Button Clicked', {
            objectType: ObjectTypes.Button,
            buttonLabel: submitButtonLabel,
            context: analyticsPrefix,
            taskId: task?.id,
            isEmergency,
            residentId: residentToUse?.id || residentId,
            notesLength: notes?.length || 0,
        });

        if (isLoggingCare) {
            addResidentActivity();
        } else {
            handleResolveTask();
        }
    };

    return (
        <>
            <DrawerTitle title={title} />

            <form onSubmit={handleSubmit}>
                <DrawerBody>
                    <FormControl fullWidth sx={{ py: 0 }}>
                        <TextField
                            id="notes"
                            label="Enter notes here (optional)"
                            multiline
                            rows={4}
                            variant="outlined"
                            value={notes}
                            error={isOverCharacterLimit}
                            helperText={
                                isOverCharacterLimit
                                    ? `${difference} characters over the limit`
                                    : `${difference} characters remaining`
                            }
                            onChange={({ target }) => setNotes(target.value)}
                        />
                    </FormControl>
                </DrawerBody>

                <DrawerFooter sx={{ gap: 2 }}>
                    <Button
                        fullWidth
                        color="primary"
                        type="submit"
                        variant="contained"
                        loading={shouldDisableActions}
                        disabled={isOverCharacterLimit}
                        disableElevation
                    >
                        {submitButtonLabel}
                    </Button>

                    {showCancelAction && (
                        <Button color="primary" disableElevation fullWidth onClick={handleGoBack}>
                            Go Back
                        </Button>
                    )}
                </DrawerFooter>
            </form>
        </>
    );
}

export default ResolveTaskDrawer;
