import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface ManagedConfigs {
    imei?: string;
    serial_number?: string;
    wifi_mac_address?: string;
    udid?: string;
    device_name?: string;
    mdm_device_id?: string;
    iccid?: string;
    phone_number?: string;
    device_user_name?: string;
    device_user_email?: string;
}

export interface ReactNativeState {
    deviceDetails?: {
        batteryLevel?: number;
        // The deviceUniqueId is unique to the specific application running on a particular device. It is not unique to the device itself.
        deviceUniqueId?: string;
        managedConfigs?: ManagedConfigs;
    };
    isReactNative: boolean;
    notification: { token?: string };
}

const initialState: ReactNativeState = {
    deviceDetails: undefined,
    isReactNative: false,
    notification: { token: undefined },
};

export const reactNativeSlice = createSlice({
    name: 'reactNative',
    initialState,
    reducers: {
        setDeviceDetails: (state, action: PayloadAction<ReactNativeState['deviceDetails']>) => {
            state.deviceDetails = action.payload;
        },
        setIsReactNative: (state, action: PayloadAction<boolean>) => {
            state.isReactNative = action.payload;
        },
        setNotificationToken: (state, action: PayloadAction<string>) => {
            state.notification.token = action.payload;
        },
    },
    selectors: {
        selectIsReactNative: (state: ReactNativeState) => state.isReactNative,
        selectDeviceDetails: (state: ReactNativeState) => state.deviceDetails,
        selectNotificationToken: (state: ReactNativeState) => state.notification?.token,
    },
});

export const { setDeviceDetails, setIsReactNative, setNotificationToken } =
    reactNativeSlice.actions;

export const { selectDeviceDetails, selectIsReactNative, selectNotificationToken } =
    reactNativeSlice.selectors;

export default reactNativeSlice.reducer;
