import parsePhoneNumber, { type E164Number } from 'libphonenumber-js';
import { MuiTelInput } from 'mui-tel-input';
import { useContext, useEffect, useState, useMemo } from 'react';
import { useForm, Controller, SubmitHandler } from 'react-hook-form';

import { useAuth0 } from '@auth0/auth0-react';
import { datadogLogs } from '@datadog/browser-logs';
import { Typography, Stack, Divider, Button } from '@mui/material';
import { Box } from '@mui/material';

import AppVersion from '../fragments/AppVersion';
import webAuth from './AuthConfigManager';
import { AuthErrorHeader } from './AuthHeader';
import EnterpriseBranding from './EnterpriseBranding';
import { AnalyticsContext } from 'src/AnalyticsContext';
import {
    SageLoginCodePhoneNumberProd,
    SageLoginCodePhoneNumberDev,
} from 'src/constants/PhoneNumbers';
import { PreferredCountryCodes, SupportedCountryCodes } from 'src/constants/SupportedCountryCodes';
import useEnvironment from 'src/hooks/useEnvironment';
import { Formats, formatPhoneNumber } from 'src/utils/formUtils';
import {
    getPhoneNumberValidationRules,
    limitPhoneNumberLength,
} from 'src/utils/phoneNumberInputUtils';

import './styles/authFlow.scss';

interface Auth0Error extends Error {
    code?: string;
    description?: string;
}

interface PhoneNumberLoginProps {
    onNext: (phoneNumber: E164Number) => void;
}

type FormValues = {
    phoneNumber: string;
};

const PageName = 'Phone Number Login page';

export default function PhoneNumberLogin(props: PhoneNumberLoginProps) {
    const { onNext } = props;
    const { loginWithRedirect } = useAuth0();
    const { trackPageView, trackEvent } = useContext(AnalyticsContext);
    const { isProd } = useEnvironment();

    const [isFetchingCode, setIsFetchingCode] = useState(false);
    const [phoneNumberValidationError, setPhoneNumberValidationError] = useState<Auth0Error>();

    const prodPhoneNumber = formatPhoneNumber(SageLoginCodePhoneNumberProd, Formats.National);
    const devPhoneNumber = formatPhoneNumber(SageLoginCodePhoneNumberDev, Formats.National);

    const { handleSubmit, control, formState } = useForm<FormValues>({
        mode: 'onChange',
        defaultValues: { phoneNumber: '' },
    });

    const { errorTitle, errorCaption } = useMemo(() => {
        if (phoneNumberValidationError?.description?.includes('21610')) {
            return {
                errorTitle: 'Your phone is unsubscribed from login codes',
                errorCaption: `Text "start" to ${
                    isProd ? prodPhoneNumber : devPhoneNumber
                } from the phone number you entered and try again.`,
            };
        }
        return {
            errorTitle: 'Not a correct phone number',
            errorCaption:
                'Check the number below for mistakes. If the number is correct, ask your manager to check your profile',
        };
    }, [devPhoneNumber, isProd, phoneNumberValidationError?.description, prodPhoneNumber]);

    const onSubmit: SubmitHandler<FormValues> = (data: FormValues) => {
        setIsFetchingCode(true);
        setPhoneNumberValidationError(undefined);

        trackEvent('Phone Number Submitted', { pageName: PageName });

        let cleanPhoneNumber: E164Number | undefined;
        try {
            cleanPhoneNumber = parsePhoneNumber(data.phoneNumber)?.number;
        } catch (parseError: any) {
            datadogLogs.logger.error(
                'Error parsing phone number',
                { message: 'Error parsing phone number', input: data.phoneNumber },
                parseError
            );
            setPhoneNumberValidationError(parseError);
            return;
        }

        webAuth.passwordlessStart(
            { connection: 'sms', send: 'code', phoneNumber: cleanPhoneNumber!.toString() },
            (err: any) => {
                if (err) {
                    setPhoneNumberValidationError(err);
                    window.scrollTo({ top: 0 });

                    /**
                     * "bad.connection" related errors are not actionable because they indicate
                     * scenarios where users are trying to use passwordless login using a phone
                     * number that is not associated with a known account in Sage's Auth0 tenant.
                     *
                     * Therefore, we log these as warnings instead of errors.
                     */
                    const { code, description } = err;
                    const messageContext = {
                        message: 'Error requesting passwordless code',
                        input: data.phoneNumber,
                        code,
                        description,
                    };
                    if (code !== 'bad.connection' && description !== 'Public signup is disabled') {
                        datadogLogs.logger.error(
                            'Error requesting passwordless code',
                            messageContext,
                            err
                        );
                    } else {
                        datadogLogs.logger.warn(
                            'Error requesting passwordless code',
                            messageContext,
                            err
                        );
                    }
                } else {
                    onNext(cleanPhoneNumber!);
                }
                setIsFetchingCode(false);
            }
        );
    };

    const handleLoginRedirect = () => {
        trackEvent('Log In Using Email Clicked', { pageName: PageName });
        loginWithRedirect();
    };

    const handlePhoneNumberChange = (value: string, onChange: (value: any) => void) => {
        if (value.startsWith('+1 1 ')) {
            // Remove the extra '1' that is added by the library
            onChange(limitPhoneNumberLength(value.replace('+1 1 ', '+1 ')));
        } else if (!value.startsWith('+1')) {
            // Add the default country code if it is missing
            onChange(limitPhoneNumberLength(`+1 ${value}`));
        } else {
            onChange(limitPhoneNumberLength(value));
        }
    };

    useEffect(() => {
        trackPageView(PageName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <div className="authHeader">
                {phoneNumberValidationError && (
                    <AuthErrorHeader title={errorTitle} caption={errorCaption} />
                )}
            </div>
            <Box
                sx={{
                    p: 2.5,
                    flexGrow: 1,
                }}
            >
                <EnterpriseBranding />
                <Typography
                    variant="h1"
                    sx={{
                        fontWeight: 'bold',
                        mb: 1,
                    }}
                >
                    Welcome to Sage!
                </Typography>

                <Typography
                    variant="body2"
                    component="p"
                    sx={{
                        mb: 4,
                    }}
                >
                    Enter your phone number below to continue.
                </Typography>

                <form onSubmit={handleSubmit(onSubmit)}>
                    <Stack
                        sx={{
                            gap: 2,
                        }}
                    >
                        <Controller
                            name="phoneNumber"
                            control={control}
                            rules={getPhoneNumberValidationRules({ isRequired: true })}
                            render={({
                                field: { ref: fieldRef, onChange, value, ...fieldProps },
                                fieldState: { invalid, error },
                            }) => (
                                <MuiTelInput
                                    {...fieldProps}
                                    forceCallingCode
                                    focusOnSelectCountry
                                    value={value ?? ''}
                                    inputRef={fieldRef}
                                    onlyCountries={SupportedCountryCodes}
                                    preferredCountries={PreferredCountryCodes}
                                    defaultCountry={PreferredCountryCodes[0]}
                                    onChange={(value) => handlePhoneNumberChange(value, onChange)}
                                    // TextFieldProps
                                    error={invalid}
                                    fullWidth
                                    helperText={error?.message}
                                    label="Phone Number"
                                    variant="outlined"
                                />
                            )}
                        />

                        <Button
                            disableElevation
                            disabled={isFetchingCode || !formState.isValid}
                            fullWidth
                            loading={isFetchingCode}
                            type="submit"
                            variant="contained"
                        >
                            Continue
                        </Button>
                    </Stack>
                </form>

                <Stack sx={{ mt: 2.5, gap: 2.5 }}>
                    <Typography variant="body2" sx={{ color: 'grey.900' }}>
                        By continuing, you agree to receive a 6-digit verification code via text
                        message.
                    </Typography>

                    <Divider>
                        <Typography
                            variant="body1"
                            component="span"
                            sx={{ color: 'grey.500', textTransform: 'uppercase' }}
                        >
                            or
                        </Typography>
                    </Divider>

                    <Button variant="outlined" fullWidth onClick={handleLoginRedirect}>
                        Log In Using Email
                    </Button>
                </Stack>
            </Box>
            <AppVersion />
        </>
    );
}
